<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <div class="w-full h-full pt-5">
      <div class="flex">
        <back-button
          label="Back"
          @onClick="$router.back()"
          variant="secondary"
          class="mr-4"
        />
        <div
          class="mt-4"
          style="border-left: 1px solid #878E99; height:1.5rem"
        />
        <h1 class="text-xl  text-left font-extrabold ml-4 mt-3 mr-8">
          <span v-if="$route.params.id">
            Edit Group
          </span>
          <span v-else>
            New Group
          </span>
        </h1>
      </div>
      <div class=" mx-3">
        <Card class="mt-6 p-5">
          <p class="font-bold text-lg text-darkPurple">
            Group Details
          </p>

          <div class="grid grid-cols-12 gap-7 mt-6">
            <div class="col-span-6">
              <CText
                placeholder="--Enter--"
                variant="w-full"
                label="Name"
                :rules="['required']"
                v-model="group.name"
              />
            </div>
            <div class="col-span-6">
              <c-text
                placeholder="--Enter--"
                label="Description"
                v-model="group.description"
                :rules="['required']"
                variant="w-full"
              />
            </div>
            <div class="col-span-6">
              <c-select
                placeholder="--Select--"
                label="Type"
                :options="groupType"
                :rules="['required']"
                v-model="group.type"
                variant="w-full"
              />
            </div>
            <div class="col-span-6">
              <c-text
                placeholder="--Enter--"
                label="Email ID"
                :rules="EmailRule"
                v-model="group.email"
                variant="w-full"
                :disabled="disableEmail"
              />
            </div>
            <div class="px-3">
              <div class="mt-4" v-if="isEdit">
                <Button
                  class="bg-dynamicBackBtn text-white buttons"
                  @click="handleSubmit(editGroup)"
                  :disabled="disableBtn"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Card>
        <Card class="mb-6 p-5 mt-3">
          <p class="font-bold text-lg text-darkPurple">
            Group Members
          </p>
          <div class="col-span-12 mb-4 flex flex-col">
            <c-select
              class="mt-3 mb-5 w-1/3"
              :options="searchResult"
              placeholder="Select Employees"
              v-model="selected"
              v-if="isEdit"
              @onChange="selectedEmployees($event)"
            />
            <c-select
              class="mt-3 mb-5 w-1/3"
              :options="searchResult"
              placeholder="Select Employees"
              v-model="selected"
              v-else
            />
            <div class="mt-12 flex flex-wrap">
              <div
                v-for="(item, i) in orgUsers"
                :key="i"
                class="user-card min-w-min cursor-pointer mb-6 justify-between flex px-5 py-6 mr-5 bg-white"
              >
                <div class="flex">
                  <img
                    class="mr-5"
                    :src="item.photo"
                    v-if="item.photo"
                    alt="profile pic"
                    style="height: 30px; width: 30px; border-radius: 5px"
                  />
                  <div
                    style="height: 35px; width: 35px; border-radius: 5px;"
                    class="text-blueCrayola border text-center font-semibold pt-2 mr-5"
                    v-else
                  >
                    {{ $getInitials(`${item.fname} ${item.lname}`) }}
                  </div>
                  <div class="flex ">
                    <div class="flex flex-col w-auto">
                      <small class="text-accent font-bold"
                        >{{ toTitleCase(item.fname) }}
                        {{ toTitleCase(item.lname) }}</small
                      >
                      <small style="font-size: 10px; color: #9E9E9E;">{{
                        item.email
                      }}</small>
                    </div>
                    <small
                      class="mt-2 ml-8 font-semibold w-auto"
                      style="font-size: 9px; align-self: center;"
                      v-if="!($route.params.id)"
                    >
                      <c-select
                        v-if="item.id == $AuthUser.id"
                        v-model="item.isAdmin"
                        variant="!border-0"
                        :options="Admin"
                        class="w-full"
                      />
                      <c-select
                        v-else
                        :options="Admin"
                        v-model="item.isAdmin"
                        class="w-full noBorder"
                      />
                    </small>
                    <small v-else />
                  </div>
                </div>
                <div
                  @click="removeItem(i, item.Group_id, $route.params.id)"
                  class="mt-n3 ml-12 w-auto"
                  style="align-self: end;"
                >
                  <icon icon-name="close" size="s" class="mt-1" />
                </div>
              </div>
            </div>
          </div>
        </Card>
        <div class="px-3">
          <div class="flex mt-4" v-if="!isEdit">
            <Button
              class="bg-dynamicBackBtn text-white buttons"
              @click="handleSubmit(validateInput)"
              :disabled="disableBtn"
            >
              Save
            </Button>
            <Button
              class="buttons"
              @click="
                $router.push({
                  name: 'UsersAndGroups',
                  query: { currentTab: 'Groups' }
                })
              "
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Card from "@/components/Card";

export default {
  name: "CreateGroup",
  components: {
    ValidationObserver,
    BackButton,
    Card,
    CText,
    CSelect
  },
  data() {
    return {
      isEdit: false,
      disableEmail: false,
      disableBtn: false,
      searchResult: [],
      orgUsers: [],
      superAdminId: '',
      EmailRule: [
        "required",
        {
          name: "email",
          rule: v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        }
      ],
      selectAdmin: null,
      superAdmin: "SuperAdmin",
      selected: "",
      Admin: [
        { name: "Admin", id: "true" },
        { name: "Member", id: "false" }
      ],
      groupType: [
        { name: "Management", id: "management" },
        { name: "Project", id: "project" }
      ],
      group: {
        name: null,
        description: null,
        type: null,
        email: null
      }
    };
  },
  watch: {
    selected(value) {
      let result;
      this.searchResult.forEach(element => {
        if (element.id === value) {
          result = this.orgUsers.find(item => item.id === value);
          if (result) {
            return;
          }
          this.orgUsers.push({ isAdmin: 'false', ...element });
        }
      });
    }
  },
  methods: {
    selectedEmployees(value) {
      const payload = [
        {
          userId: value,
          groupId: this.group.id,
          isAdmin: "false"
        }
      ];
      this.$_addMemberToGroup(payload).then(result => {
        this.$toasted.success(result.data.message, {
          duration: 5000
        });
      });
    },

    removeItem(value, userId, params) {
      this.orgUsers.splice(value, 1);

      if (params !== "") {
        this.$_deleteMemberFromGroup(userId).then(result => {
          this.$toasted.success(result.data.message, {
            duration: 3000
          });
        });
      }
    },

    validateInput() {
      this.createGroup();
    },

    async createGroup() {
      const admins = [];
      this.disableBtn = true;

      this.group.groupUsers = [];
      this.group.superAdminId = null;
      this.group.orgId = this.$AuthUser.orgId;
      this.orgUsers.forEach(item => {
        this.group.groupUsers.push({
          userId: item.id,
          isAdmin: (item.isAdmin === 'true')
        });
        if (item.isAdmin) {
          this.group.superAdminId = item.id;
        }
      });

      this.group.groupUsers.filter(a => {
        if (a.isAdmin) {
          admins.push(1);
        }
        return {};
      });

       if (admins.length > 1) {
        this.$toasted.error("You cannot assign more than 1 admin to a group", {
          duration: 5000
        });
        this.disableBtn = false;
      } else {
        this.$_createGroups(this.group)
          .then(() => {
            this.$toasted.success("Groups created successfully", {
              duration: 3000
            });
            this.$router.push({
              name: "UsersAndGroups",
              query: { currentTab: "Groups" }
            });
            this.disableBtn = false;
          })
          .catch(() => {
            this.$toasted.error("Groups not created", { duration: 3000 });
            this.disableBtn = false;
          });
      }
    },

    getOneGroup() {
      if (this.$route.params.id) {
        this.isEdit = true;
        this.disableEmail = true;
        this.$_getOneGroup(this.$route.params.id).then(result => {
          this.group = result.data.group;
          this.superAdminId = this.group.superAdminId;
          this.orgUsers = result.data.group.users.map(element => ({
            Group_id: element.group_users.id,
            id: element.id,
            fname: element.fname,
            lname: element.lname,
            email: element.email,
            isAdmin: (element.id === this.superAdminId).toString()
          }));
          // this.orgUsers.push({ ...result.data.group.superAdmin });
        });
      }
    },

    async getAllUsers() {
      const result = await this.$_getAllOrgUsers("");
      result.data.forEach(element => {
        if (!this.$route.params.id) {
          if (this.$AuthUser.id === element.id) {
            this.orgUsers.push({ isAdmin: 'true', ...element });
            this.searchResult.push({
              name: `${element.fname} ${element.lname}`,
              id: element.id,
              ...element
            });
          }
        }
      });
      this.searchResult = result.data.map(element => ({
        name: ` ${element.fname} ${element.lname}`,
        id: element.id,
        ...element
      }));
    },

    async editGroup() {
      this.disableBtn = true;
      if (this.$route.params.id) {
        this.orgUsers.forEach(item => {
          if (item.isAdmin === "true") {
            this.group.superAdminId = item.id;
          }
        });

        const payload = {
          id: this.group.id,
          isActive: this.group.isActive,
          name: this.group.name,
          type: this.group.type,
          superAdminId: this.group.superAdminId,
          description: this.group.description
        };

        this.$_editOneGroup(payload).then(() => {
          this.$toasted.success("Group updated successfully", {
            duration: 5000
          });
          this.disableBtn = false;
          this.$router.push({
            name: 'UsersAndGroups',
            query: {currentTab: 'Groups'}
          });
        });
      }
    }
  },
  mounted() {
    this.getOneGroup();
    this.getAllUsers();
  }
};
</script>

<style scoped>
.buttons {
  width: 126px;
  height: 44px;
  border-radius: 5px;
}
.user-card {
  max-width: 390px;
  min-width: 390px;
  height: 90px;
  border: 1px dashed #878e99;
  border-radius: 5px;
}
.noBorder {
  border: none !important;
}
.search {
  width: 639px;
}
</style>
